<style lang="scss" scoped>
.empty-container {
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: top;
  margin-top: 100px;
  text-align: center;
  .register-button {
    margin-top: 25px;
  }
}
</style>

<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <h2 class="mb-3">{{ $capitalize($tc("model.teams_teams_title")) }}</h2>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            :label="
              $capitalize($tc('model.teams_search_team'))
            "
            solo
            hide-details
            single-line
            prepend-inner-icon="mdi-magnify"
            v-model="filter"
            @keyup.enter="getTeams()"
          ></v-text-field>
        </v-col>
        <v-col class="text-right">
          <v-btn @click="getTeams()" icon class="mr-2">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-btn v-if="$store.getters['auth/getScope']('teams.edit')" :to="{ name: 'teams_new' }" color="primary">
              <v-icon left>mdi-plus</v-icon> {{ $capitalize($tc("model.teams_new_team_button")) }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-data-table
              :headers="headers"
              :items="teams"
              :items-per-page="5"
              :disabled="loading"
              :options.sync="options"
              :server-items-length="totalItems"
              :loading="loading"
              :loading-text="$capitalize($tc('model.leads_table_loading'))"
              fixed-header
              max-height="500px"
            >
              <template v-slot:header.name>
                {{ $capitalize($tc("model.teams_name_table_column", 1)) }}
              </template>
              <template v-slot:header.leader_name>
                {{ $capitalize($tc("model.teams_leader_table_column", 1)) }}
              </template>
              <template v-slot:header.managers_qty>
                {{ $capitalize($tc("model.teams_managers_table_column", 1)) }}
              </template>
              <template v-slot:header.unities_qty>
                {{ $capitalize($tc("model.teams_units_table_column", 1)) }}
              </template>
              <template v-slot:header.leads_qty>
                {{ $capitalize($tc("model.teams_leads_table_column", 1)) }}
              </template>
              <template v-slot:header.actions>
                {{ $capitalize($tc("model.teams_actions_table_column", 1)) }}
              </template> 

              <template v-slot:item.leader_name="{ item }">
                <div v-if="item.leader_name">
                  <v-chip
                    ><v-icon left size="20">mdi-account</v-icon>
                    {{ item.leader_name }}</v-chip
                  >
                </div>
                <div v-else class="grey--text">{{ $capitalize($tc("model.teams_none_leader")) }}</div>
              </template>
              <template v-slot:item.actions="{ item }">
                <ScopeProvider scope="teams.edit">
                  <v-btn :to="'/teams/' + item.id" exact icon color="primary">
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-btn>
                </ScopeProvider>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <!-- <div v-else-if="loading" class="empty-container">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else class="empty-container">
        <v-row>
          <v-col>
            <span> {{ $capitalize($tc("model.teams_no_team_registered")) }}... </span>
            <br />
            <ScopeProvider scope="teams.add">
                <v-btn
                    :to="{ name: 'teams_new' }"
                    color="primary"
                    outlined
                    class="register-button"
                >
                    <v-icon left>mdi-plus</v-icon> {{ $capitalize($tc("model.teams_new_team_button")) }}
                </v-btn>
            </ScopeProvider>
          </v-col>
        </v-row>
      </div> -->
    </v-container>
  </div>
</template>

<script>
import { index } from "@/services/teams";
import UserAvatar from "@/components/UserAvatar";
import ScopeProvider from "@/components/ScopeProvider";

export default {
  name: "teams",
  components: { ScopeProvider },
  data: () => ({
    teams: [],
    loading: false,
    headers: [
      {
        text: "Nome",
        value: "name",
      },
      {
        text: "Líder",
        value: "leader_name",
      },
      {
        text: "Gestores",
        value: "managers_qty",
        align: "center",
      },
      {
        text: "Unidades",
        value: "unities_qty",
        align: "center",
      },
      {
        text: "Leads",
        value: "leads_qty",
        align: "center",
      },
      {
        text: "Ações",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],
    totalItems: 0,
    options: {
      itemsPerPage: 10,
      sortBy: ["name"],
      sortDesc: [],
      page: 1,
    },
    filter: "",
  }),
  methods: {
    async getTeams() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.loading = true;

      this.teams = [];

      try {
        const response = await index({
          orderBy: sortBy[0] ? sortBy[0] : "",
          sortDesc: sortDesc[0] ? sortBy[0] : "",
          page,
          perPage: itemsPerPage,
          filter: this.filter || "",
        });
        this.teams = response.data;
        this.totalItems = response.total;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    options: {
      handler(value) {
        this.getTeams();
      },
      deep: true,
    },
  },
  created() {
    this.getTeams();
  },
};
</script>